import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField,AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getIncidents } from 'app/entities/incident/incident.reducer';
import { getEntities as getCapas } from 'app/entities/capa/capa.reducer';
import { getEntities as getManagementReviewBoards } from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { createEntity, getEntity, reset, updateEntity } from './incident.reducer';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import BackButton from 'app/common/components/ui/button/BackButton';

export interface IIncidentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const IncidentUpdate = (props: IIncidentUpdateProps) => {
  const { incidentEntity, incidents, capas, managementReviewBoards, loading, updating } = props;

  const [parentId, setParentId] = useState('0');
  const [capaId, setCapaId] = useState('0');
  const [mrbId, setMrbId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const handleClose = () => {
    props.history.goBack();
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getIncidents();
    props.getCapas();
    props.getManagementReviewBoards();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(incidentEntity.createdDate);
    values.updatedDate = convertDateTimeToServer(incidentEntity.updatedDate);

    if (errors.length === 0) {
      const entity = {
        ...incidentEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const onClickBackHandler = () => {
    props.history.goBack();
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="egcapaApp.incident.home.createOrEditLabel">Edit a Incident</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : incidentEntity} onSubmit={saveEntity}>
              {!isNew && <input type="hidden" name="id" value={incidentEntity.id}></input>}
              <input type="hidden" name="status" value={incidentEntity.status}></input>
              {incidentEntity.parent == null ? '' : <input type="hidden" name="parent.id" value={incidentEntity.parent.id}></input>}
              {incidentEntity.capa == null ? '' : <input type="hidden" name="capa.id" value={incidentEntity.capa.id}></input>}
              {!isNew ? (
                <span>
                  <AvGroup>
                    <Label id="incidentIdLabel" for="incident-incidentId">
                      Incident Id
                    </Label>
                    <AvField id="incident-incidentId" type="text" name="incidentId" disabled />
                  </AvGroup>
                </span>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="incident-title">
                  Title
                </Label>
                <AvField
                  id="incident-title"
                  type="text"
                  name="title"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 100, errorMessage: 'The Title must not exceed 100 characters.' },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="incident-description">
                  Description
                </Label>
                <AvField
                  id="incident-description"
                  type="textarea"
                  name="description"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 2000, errorMessage: 'The Description must not exceed 2000 characters.' },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="actionTakenLabel" for="incident-actionTaken">
                  Action Taken
                </Label>
                <AvField
                  id="incident-actionTaken"
                  type="textarea"
                  name="actionTaken"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 2000, errorMessage: 'The Action Taken must not exceed 2000 characters.' },
                  }}
                />
              </AvGroup>
              {!isNew ? (
                <span>
                  <AvGroup>
                    <Label id="statusLabel" for="incident-status">
                      Status
                    </Label>
                    <AvInput
                      id="incident-status"
                      type="select"
                      className="form-control"
                      name="status"
                      disabled
                      value={(!isNew && incidentEntity.status) || 'NEW'}
                    >
                      <option value="NEW">NEW</option>
                      <option value="CLOSED">CLOSED</option>
                      <option value="INITIATE_CAPA">INITIATE_CAPA</option>
                    </AvInput>
                  </AvGroup>

                  {incidentEntity.parent == null ? (
                    ''
                  ) : (
                    <AvGroup>
                      <Label for="incident-parent">Parent</Label>
                      <div>
                        <a href={'/incident/' + incidentEntity.parent.id}>{incidentEntity.parent.incidentId}</a>
                      </div>
                    </AvGroup>
                  )}
                  {incidentEntity.capaId == null ? (
                    ''
                  ) : (
                    <AvGroup>
                      <Label for="incident-capa">CAPA</Label>
                      <div>
                        <a href={'/capa/' + incidentEntity.capaId}>{incidentEntity.capaId}</a>
                      </div>
                    </AvGroup>
                  )}
                  {incidentEntity.followupId == null ? (
                    ''
                  ) : (
                    <AvGroup>
                      <Label for="incident-followup">Followup</Label>
                      <div>
                        <a href={'/followup/' + incidentEntity.followupId}>{incidentEntity.followupId}</a>
                      </div>
                    </AvGroup>
                  )}
                </span>
              ) : null}
              <BackButton id="cancel-save" replace={true} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </BackButton>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  incidents: storeState.incident.entities,
  capas: storeState.capa.entities,
  managementReviewBoards: storeState.managementReviewBoard.entities,
  incidentEntity: storeState.incident.entity,
  loading: storeState.incident.loading,
  updating: storeState.incident.updating,
  updateSuccess: storeState.incident.updateSuccess,
});

const mapDispatchToProps = {
  getIncidents,
  getCapas,
  getManagementReviewBoards,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentUpdate);
