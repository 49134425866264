import React from 'react';
import './footer.scss';
import { Col, Row } from 'reactstrap';

const Footer = (props) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer page-content">
      <Row>
        <Col md="12">
          <p>Copyright © {currentYear} E-gineering, Inc</p>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;